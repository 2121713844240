<template>
  <div class="home" ref="home" id="home">
    <!-- banner -->
    <div class="home_section_top bgc2">
      <div class="home_section_top_container space-between-row">
        <div class="col">
          <div class="home_section_top_title fc1">{{ this.$t("bitjungle.banner.title") }}</div>
          <div class="home_section_top_subtitle fc2">{{ this.$t("bitjungle.banner.subTitle") }}</div>
        </div>
        <div class="col">
          <img class="home_section_top_img" :src="require(`@/assets/img/bitjungle-banner.png`)"/>
        </div>
      </div>
    </div>

    <!-- 公司简介 -->
    <div class="home_section home_section_intro bc1">
      <div class="home_section_container space-between-row">
        <img :src="require(`@/assets/img/bitjungle-us.png`)" width="456px" height="272px"/>
        <div class="col">
          <label class="fc1">{{ this.$t("bitjungle.intro.title") }}</label>
          <p class="fc2">{{ this.$t("bitjungle.intro.p[0]") }}</p>
          <p class="fc2">{{ this.$t("bitjungle.intro.p[1]") }}</p>
          <p class="fc2">{{ this.$t("bitjungle.intro.p[2]") }}</p>
        </div>
      </div>
    </div>

    <!-- 发展历程 -->
    <div class="home_section home_section_intro bc1">
      <div class="home_section_container col">
        <div class="home_section_text w100">
          <div class="home_section_title1 fc1">
            <label>{{ this.$t("bitjungle.time.title") }}</label>
          </div>
        </div>
        <time-line :timelineItems="this.$t('bitjungle.time.list')"/>
      </div>
    </div>
    <!-- 使命及远景 -->
    <div class="home_section home_section_advantages bc1">
      <div class="home_section_container space-between-row">
        <div class="home_section_text w100">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("bitjungle.target.title") }}</label>
          </div>
          <div class="home_section_sub_list">
            <div class="home_section_3 hover-bgc1 border1 col">
              <img :src="require(`@/assets/img/bitjungle-fate.png`)"/>
              <label class="fc1">{{ this.$t("bitjungle.target.info1.title") }}</label>
              <div class="fc2">{{ this.$t("bitjungle.target.info1.subTitle") }}</div>
            </div>
            <div class="home_section_3 hover-bgc1 border1 col">
              <img :src="require(`@/assets/img/bitjungle-target.png`)"/>
              <label class="fc1">{{ this.$t("bitjungle.target.info2.title") }}</label>
              <div class="fc2">{{ this.$t("bitjungle.target.info2.subTitle") }}</div>
            </div>
            <div class="home_section_3 hover-bgc1 border1 col">
              <img :src="require(`@/assets/img/bitjungle-value.png`)"/>
              <label class="fc1">{{ this.$t("bitjungle.target.info3.title") }}</label>
              <div class="fc2">{{ this.$t("bitjungle.target.info3.subTitle") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 办公地点-->
    <div class="home_section home_section_cooperation bc1">
      <div class="home_section_container space-between-row">
        <div class="home_section_text w100">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("bitjungle.location.title") }}</label>
          </div>
          <div class="home_section_sub_list">
            <div class="home_section_3 bgc3 hover-border hover-no-bg">
              <div class="row">
                <img :src="$t(`bitjungle.location.location[0].img`)"/>
                <div class="col">
                  <label class="home_section_title2 fc1">{{ this.$t("bitjungle.location.location[0].title") }}</label>
                  <label class="home_section_title3 fc2">{{ this.$t("bitjungle.location.location[0].address") }}</label>
                </div>
              </div>
            </div>
            <div class="home_section_3 bgc3 hover-border hover-no-bg">
              <div class="row">
                <img :src="$t(`bitjungle.location.location[1].img`)"/>
                <div class="col">
                  <label class="home_section_title2 fc1">{{ this.$t("bitjungle.location.location[1].title") }}</label>
                  <label class="home_section_title3 fc2">{{ this.$t("bitjungle.location.location[1].address") }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section8 social-->
    <div class="home_section home_section_social bc1">
      <div class="home_section_container space-between-row">
        <div class="home_section_text">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("home.section8.title") }}</label>
          </div>
          <div class="home_section_title2 space-between-row fc2" style="margin-top: 16px;">
            <label>{{ this.$t("home.section8.subTitle") }}</label>
          </div>
          <div class="row">
            <img class="hover-jump" @click="goBlank(x_url)" :src="require(`@/assets/img/icon-x.png`)" width="48" height="48"/>
            <img class="hover-jump" @click="goBlank(redbook_url)" :src="require(`@/assets/img/icon-redbook.png`)" width="48" height="48"/>
            <img class="hover-jump" @click="goBlank(tiktok_url)" :src="require(`@/assets/img/icon-tiktok.png`)" width="48" height="48"/>
            <el-popover
              :popper-class="'qrcode-popover shadow-'+theme"
              placement="bottom-start"
              width="240"
              :visible-arrow="false"
              trigger="click">
              <img slot="reference" class="hover-jump" :src="require(`@/assets/img/icon-wechat.png`)" width="48" height="48"/>
              <img class="code-item-img" :src="require(`@/assets/img/qrcode-weixin-${theme}.png`)" width="248" height="248"/>
            </el-popover>
          </div>
        </div>
        <div>
          <img :src="require(`@/assets/img/icon-news.png`)" width="380" height="230"/>
        </div>
      </div>
    </div>

    <BitBottom/>

  </div>
</template>

<script>
import { newsList } from "@/api/case";
import BitBottom from "@/components/BitBottom.vue";
import TimeLine from '@/components/TimeLine.vue';

export default {
  name: "home",
  components: { BitBottom , TimeLine},
  mounted() {
    this.getArtList();
    this.startAutoPlay();
  },
  beforeDestroy() {
    this.stopAutoPlay();
  },
  computed:{
    theme: function () {
      let t = this.$store.state.theme
      if(t==null){
        t = localStorage.getItem("theme")
      }
      if(t == null) t = 'dark'
      return t
    },
    lang: function () {
      return this.$i18n.locale
    },
    productList: function () {
      return this.$t("zhongkui.product.list")
    },
    timelineData: function () {
      return this.$t("bitjungle.time.list")
    }
  },
  data() {
    return{
      timer: null,
      hoverIndex: 0,
      newsList: [],
      showRQCode1: false,
      showRQCode2: false,
      x_url: "https://x.com/BitJungle_team",
      tiktok_url: "https://www.douyin.com/user/MS4wLjABAAAAjmzdoKmLOD_yxzwFID-IiIHnjI3Nmm5M2bDuzIAkhj8cBl1mPmStgSPmW9fHpnva",
      redbook_url: "https://www.xiaohongshu.com/user/profile/6698756b000000000f03469e"
    }
  },
  methods: {
    startAutoPlay() {
      this.stopAutoPlay(); // 确保不会创建多个定时器
      this.timer = setInterval(() => {
        this.hoverIndex = (this.hoverIndex + 1) % this.productList.length;
      }, 5000); // 每 5s 轮播
    },
    stopAutoPlay(index = null) {
      clearInterval(this.timer);
      if (index !== null) {
        this.hoverIndex = index; // 切换到手动 hover 的项
      }
    },
    showRQCode(index){
      if(index==1)
        this.showRQCode1 = !this.showRQCode1
      else(index==2)
        this.showRQCode2 = !this.showRQCode2
    },
    showUsQrCode(){
      this.$bus.$emit("showUsQrCode");
    },
    goBlank(url){
      window.open(url)
    },
    showMsg(msg){
      this.$message({
        showClose: false,
        customClass: 'bit-msg bit-msg-'+this.theme,
        message: msg
      });
    },
    router(path){
      if(!path || path==''){
        this.showMsg(this.$t("undoMsg"))
        return
      }
      this.$router.push(path)
    },
    getArtList(){
      newsList().then(res => {
        this.newsList = res.data.data.list
      })
    },
    contactUs() {
      this.$emit("scrollToBottom");
    },
  },
};
</script>
<style lang="scss" scoped>
.dark{
  .home_section_social {
    background-image: url("../../assets/img/banner-news-dark.png");
  }
  .code-item{
    background-image: url("../../assets/img/banner-qrcode-dark.png");
  }
}
.light{
  .home_section_social {
    background-image: url("../../assets/img/banner-news-light.png");
  }
  .code-item{
    background-image: url("../../assets/img/banner-qrcode-light.png");
  }
}
.home {
  box-sizing: border-box;
  flex: 1;
  .home_section_top {
    height: 480px;
    display: flex;
    justify-content: center;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    .home_section_top_container {
      width: 1200px;
      padding: 105px 0;
      position: relative;
      box-sizing: border-box;
      .col{
        justify-content: center;
      }
    }
    .home_section_top_title {
      height: 64px;
      line-height: 64px;
      font-size: 56px;
      font-weight: 500;
    }

    .home_section_top_btn {
      position: relative;
      margin-top: 48px;
      width: 144px;
      height: 48px;
      line-height: 48px;
      box-sizing: border-box;
      background: linear-gradient(109deg, #1C5BF9 1%, #1C5BF9 11%, #13B9CB 48%, #32E355 85%);
      font-size: 16px;
      color: #ffffff;
      cursor: pointer;
      text-align: center;
      border-radius: 4px;
      .code-item{
        position: absolute;
        top: 60px;
      }
    }

    .home_section_top_subtitle {
      margin-top: 16px;
      font-size: 24px;
      width: 564px;
    }

    .home_section_top_describe {
      font-size: 23px;
      margin-top: 5px;
      width: 530px;
    }

    .home_section_top_img{
      width: 480px;
      height: 340px;
      position: absolute;
      right: 0;
    }
  }

  .home_section_intro{
    .home_section_container {
      padding: 88px 0px;
      .home_section_text{
        margin-bottom: 40px;
      }
      .space-between-row{
        gap: 24px !important;
      }
      .col{
        width: 720px;
        justify-content: flex-start;
        align-content: flex-start;
        gap: 16px;
        .fc1{
          font-size: 36px;
          font-weight: 500;
          height: 48px;
          line-height: 48px;
        }
        .fc2{
          font-size: 20px;
          font-weight: 400;
          line-height: 32px;
        }
        p{
          white-space: pre-line;
          text-align: justify;
        }
      }
    }
  }

  .home_section_advantages {
    height: 469px !important;
    .home_section_container {
      padding: 88px 0px;
      .home_section_sub_list{
        gap: 24px !important;
        .home_section_3{
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 384px !important;
          border-radius: 4px;
          padding: 24px;
          img{
            width: 88px;
            height: 88px;
            margin-bottom: 12px;
          }
          .fc1{
            height: 28px;
            line-height: 28px;
            font-size: 20px;
            margin-bottom: 8px;
            font-weight: 500;
          }
          .fc2{
            min-height: 20px;
            line-height: 20px;
            font-size: 14px;
            width: 304px;
            text-align: center;
          }
        }
      }
    }
  }

  .home_section_cooperation {
    .home_section_container {
      padding: 88px 0px;
      .home_section_sub_list{
        gap: 24px !important;
        .home_section_3{
          width: 588px !important;
          height: 184px !important;
          padding: 24px;
          align-items: center;
          border: none;
          display: flex;
          justify-content: flex-start !important;
          img{
            width: 248px;
            height: 136px;
            margin-right: 32px;
          }
          .fc1{
            font-weight: 500;
            font-size: 20px;
            height: 28px;
            line-height: 28px;
            margin-bottom: 12px;
          }
          .fc2{
            font-size: 14px;
            height: 20px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .home_section_achievements{
    height: 172px;
    background: linear-gradient(96deg, #4C1FD3 2%, #2E62E2 26%, #31C79D 65%, #54EA72 97%);
    color: #FFFFFF;
    .achievements-item{
      gap: 4px;
    }
    .title{
      font-size: 64px;
      height: 64px;
      line-height: 64px;
      font-weight: 600;
      margin-right: 2px;
    }
    .unit{
      height: 24px;
      line-height: 24px;
      font-size: 16px;
      font-weight: 600;
    }
    .subTitle{
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      height: 24px;
    }
  }

  .home_section_case {
    height: 484px !important;
    .home_section_container {
      padding: 88px 0px;
      .home_section_sub_list{
        flex-wrap: wrap;
        width: 100%;
        gap: 24px !important;
        .home_section_sub{
          width: 384px !important;
          height: 220px !important;
          padding: 32px !important;
          .news_title{
            max-height: 64px;
            font-size: 20px;
            font-weight: 500;
            line-height: 32px;
            text-align: justify;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /* 限制为两行 */
            overflow: hidden; /* 超出部分隐藏 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
          .news_intro{
            font-size: 16px;
            font-weight: normal;
            line-height: 28px;
            max-height: 84px;
            text-align: justify;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3; /* 限制为两行 */
            overflow: hidden; /* 超出部分隐藏 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
        }
      }
    }
  }

  .home_section_social{
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 390px;
    .home_section_container{
      padding: 88px 0px;
    }
    .home_section_text{
      height: 230px;
      .home_section_title2{
        margin-top: 16px;
        font-weight: 400 !important;
      }
      .row{
        margin-top: 60px;
        gap: 24px;
        position: relative;
        img{
          cursor: pointer;
        }
        .code-item{
          position: absolute;
          right: 100px;
          top: 60px;
        }
      }
    }
  }
}
</style>
