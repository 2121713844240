<!-- Timeline.vue -->
<template>
  <div class="timeline-container w100">
    <!-- 中心时间轴线 -->
    <div class="timeline-line" :class="{'is-en':lang=='en'}"></div>

    <!-- 左侧 时间轴项 -->
    <div class="timeline-item-col col left">
      <div
        v-for="(item, index) in timelineItems"
        v-show="index % 2 === 0"
        :key="index"
        class="timeline-item"
      >
        <!-- 内容卡片 -->
        <div class="timeline-content">
          <div class="content-card bgc2">
            <ul class="content-events fc1">
              <li v-for="(event, eventIndex) in item.events" :key="eventIndex">
                {{ event }}
              </li>
            </ul>
            <div class="content-year">{{ item.time }}</div>
          </div>
          <!-- 小三角箭头 -->
          <div
            class="triangle bgc2"
            :class="{
              'triangle-right': index % 2 === 0,
              'triangle-left': index % 2 !== 0,
            }"
          ></div>
          <!-- 时间点 -->
          <div class="timeline-dot border2">
            <div class="timeline-dot-circle"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 右侧 时间轴项 -->
    <div class="timeline-item-col col right">
      <div
        v-for="(item, index) in timelineItems"
        v-show="index % 2 === 1"
        :key="index"
        class="timeline-item"
      >
        <!-- 内容卡片 -->
        <div class="timeline-content">
          <div class="content-card bgc2">
            <ul class="content-events fc1">
              <li v-for="(event, eventIndex) in item.events" :key="eventIndex">
                {{ event }}
              </li>
            </ul>
            <div class="content-year">{{ item.time }}</div>
          </div>
          <!-- 小三角箭头 -->
          <div
            class="triangle bgc2"
            :class="{
              'triangle-right': index % 2 === 0,
              'triangle-left': index % 2 !== 0,
            }"
          ></div>
          <!-- 时间点 -->
          <div class="timeline-dot border2">
            <div class="timeline-dot-circle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Timeline",
  props: {
    timelineItems: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  computed: {
    lang: function () {
      return this.$i18n.locale
    },
    theme: function () {
      let t = this.$store.state.theme;
      if (t == null) {
        t = localStorage.getItem("theme");
      }
      if (t == null) t = "dark";
      return t;
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline-container {
  position: relative;
  min-height: 519px;
}

/* 中心时间轴线 */
.timeline-line {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 2px;
  height: 70%;
  background-color: #b3b3b3;
  transform: translateX(-50%);
}
.is-en{
  height: 75%;
}
.timeline-item-col{
  gap: 32px;
  position: relative;
}

/* 事项 */
.timeline-item {
  position: relative;
  display: flex;
  align-items: center;
  width: 599px;
}
.left {
  .timeline-content {
    justify-content: flex-start;
    text-align: right;
    .content-card {
      border-radius: 8px 0 8px 8px !important;
    }
  }
}
.right {
  position: absolute;
  left: 588px;
  top: 90px;
  .timeline-content {
    flex-direction: row-reverse;
    justify-content: flex-start;
    text-align: left;
    .content-card {
      flex-direction: row-reverse;
      border-radius: 0 8px 8px 8px !important;
    }
  }
}
/* 时间点 */
.timeline-dot {
  position: relative;
  bottom: 11px;
  left: 11px;
  width: 22px;
  height: 22px;
  padding: 3px;
  border-radius: 50%;
  transform: translateX(-50%);
  background-color: #b3b3b3;
  border: 2px solid #d9d0d9;
  z-index: 1;
  .timeline-dot-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid #d9d0d9;
  }
}

/* 内容区域 */
.timeline-content {
  position: relative;
  display: flex;
  min-height: 148px;
}

/* 内容卡片 */
.content-card {
  padding: 24px 40px 24px;
  width: 564px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
}

/* 年份 */
.content-year {
  color: #155ef9;
  font-size: 20px;
  font-weight: 500;
}
/* 事件列表 */
.content-events {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0px;
}

.content-events li {
  margin-bottom: 8px;
}

.content-events li:last-child {
  margin-bottom: 0;
}

/* 小三角箭头 - 直角三角形 */
.triangle {
  width: 24px; /* 宽度 24px */
  height: 16px; /* 高度 16px */
}

.triangle-right {
  clip-path: polygon(0 0, 24px 0, 0 16px);
}

.triangle-left {
  clip-path: polygon(0 0, 24px 0, 24px 16px);
}
</style>
