var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline-container w100"},[_c('div',{staticClass:"timeline-line",class:{'is-en':_vm.lang=='en'}}),_c('div',{staticClass:"timeline-item-col col left"},_vm._l((_vm.timelineItems),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index % 2 === 0),expression:"index % 2 === 0"}],key:index,staticClass:"timeline-item"},[_c('div',{staticClass:"timeline-content"},[_c('div',{staticClass:"content-card bgc2"},[_c('ul',{staticClass:"content-events fc1"},_vm._l((item.events),function(event,eventIndex){return _c('li',{key:eventIndex},[_vm._v(" "+_vm._s(event)+" ")])}),0),_c('div',{staticClass:"content-year"},[_vm._v(_vm._s(item.time))])]),_c('div',{staticClass:"triangle bgc2",class:{
            'triangle-right': index % 2 === 0,
            'triangle-left': index % 2 !== 0,
          }}),_vm._m(0,true)])])}),0),_c('div',{staticClass:"timeline-item-col col right"},_vm._l((_vm.timelineItems),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index % 2 === 1),expression:"index % 2 === 1"}],key:index,staticClass:"timeline-item"},[_c('div',{staticClass:"timeline-content"},[_c('div',{staticClass:"content-card bgc2"},[_c('ul',{staticClass:"content-events fc1"},_vm._l((item.events),function(event,eventIndex){return _c('li',{key:eventIndex},[_vm._v(" "+_vm._s(event)+" ")])}),0),_c('div',{staticClass:"content-year"},[_vm._v(_vm._s(item.time))])]),_c('div',{staticClass:"triangle bgc2",class:{
            'triangle-right': index % 2 === 0,
            'triangle-left': index % 2 !== 0,
          }}),_vm._m(1,true)])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline-dot border2"},[_c('div',{staticClass:"timeline-dot-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline-dot border2"},[_c('div',{staticClass:"timeline-dot-circle"})])
}]

export { render, staticRenderFns }